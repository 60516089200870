<template lang="pug">
.plan-details(v-if="hasActiveDefaultPaymentMethod")
  .row
    .col
      .name {{ $t('paymentDetails') }}
  .row.mb-6
    .col-auto
      .alt-title {{ $t('paymentMethod') }}
      .details.d-flex(v-if="isBankTransfer") {{ $t('payment.bank_transfer') }}
      .details.d-flex(v-else-if="isBraintreePayment")
        img(:src="getPaymentMethodSection.image")
        .card-number {{ getPaymentMethodSection.cardOrEmail }}
        .expiration(v-if="getPaymentMethodSection.expiration") . {{ $t('expiryDate') }}: {{ getPaymentMethodSection.expiration }}
      .details(v-else-if="isShopifyPayment")
        .shopify
          img(:src="require('@/assets/admin/svg/platforms/shopify.svg')")
          .shop-name Shopify ({{ getShopifyDomain }})
        .hint(v-show="isShopifyPayment && isPageViewBasedPackage") {{ $t('cancelPlan.hints.forFreemium') }}
        .hint(v-show="isShopifyPayment && !isPageViewBasedPackage") {{ $t('cancelPlan.hints.forNotFreemiumShopify') }}
    .col.align-self-center(v-if="!isShopifyPayment")
      .link
        router-link(:to="{ name: 'payment_method' }")
          a.link {{ $t('edit') }}
  .row.mb-6(v-if="!isShopifyPayment")
    .col-auto
      .alt-title {{ $t('billingAddress') }}
      .details
        span {{ getBillingAddressSection }}
    .col.align-self-center
      .link
        router-link(:to="{ name: 'billing_address' }")
          a.link {{ $t('edit') }}
  .row(v-if="!isShopifyPayment")
    .col-auto
      .alt-title {{ cancelled || isDowngradeToFree ? $t('expiryDate') : $t('nextPayMent2') }}
      .details
        span {{ formatDate(getNextPaymentDate) }}
    .col.align-self-center(v-if="!isDowngradeToFree && !cancelled")
      .link
        router-link(to="/cancel-subscription")
          a.link(v-if="isBraintreePayment" @click.prevent="cancelSubscription" href="#") {{ $t('plan.cancel') }}
</template>
<script>
  import { mapGetters, createNamespacedHelpers } from 'vuex';
  import flexiPay from '@/mixins/planSettings';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import dateFormat from '@/mixins/dateFormat';

  const {
    mapState: mapPaymentState,
    mapGetters: mapPaymentGetters,
    mapMutations: paymentMutations,
  } = createNamespacedHelpers('payment');

  export default {
    mixins: [flexiPay, dateFormat],
    computed: {
      ...mapGetters(['isPageViewBasedPackage', 'getDowngradeInformation']),
      ...mapPaymentState(['paymentData', 'billing', 'cancelled']),
      ...mapPaymentGetters([
        'hasActiveDefaultPaymentMethod',
        'hasPaymentMethod',
        'getNextPaymentDate',
        'isShopifyPayment',
        'isBraintreePayment',
        'isBankTransfer',
      ]),
      isDowngradeToFree() {
        return this.getDowngradeInformation?.to === 'FREE';
      },
      getPaymentMethodSection() {
        const { paymentMethods } = this.paymentData;
        const defaultPaymentMethod = paymentMethods.find((method) => method.default);
        const { imageUrl, expirationDate, type } = defaultPaymentMethod;
        if (type === 'paypal') {
          return { cardOrEmail: defaultPaymentMethod.email, image: imageUrl, expiration: null };
        }

        return {
          cardOrEmail: defaultPaymentMethod.maskedNumber,
          image: imageUrl,
          expiration: expirationDate,
        };
      },
      getBillingAddressSection() {
        return `${this.billing.address}, ${this.billing.city}, ${this.billing.country}`;
      },
      getShopifyDomain() {
        const shopifyShop = this.account.settings.shops.find((shop) => shop.pay === 1);
        return shopifyShop?.live_domain;
      },
    },
    methods: {
      ...paymentMutations(['setPlan']),
      cancelSubscription() {
        if (this.$i18n.locale === 'hu') {
          this.$modal.show('dialog', {
            title: '',
            text: this.$t('notifications.cancelConfirm'),
            buttons: [
              {
                title: this.$t('yes'),
                default: true,
                class: getBrandedClassString({ primary: true }, 'mr-3'),
              },
              {
                title: this.$t('no'),
                class: getBrandedClassString({ secondary: true }),
                handler: () => {
                  this.$modal.hide('dialog');
                  this.$modal.show('cancel-subscription-hu');
                },
              },
            ],
          });
        } else {
          this.$modal.show('cancel-subscription-en');
        }

        if (this.isPageViewBasedPackage) {
          this.setPlan('FREE');
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  .plan-details
    border: 1px solid #E3E5E8
    border-radius: 8px
    padding: 24px
    .name
      font-size: 18px
      font-weight: 700
      color: #23262A
      margin-bottom: 24px
    .alt-title
      font-size: 14px
      font-weight: 400
      color: #8F97A4
      margin-bottom: 4px
    .details
      color: #23262A
      font-weight: 700
      font-size: 14px
      img
        width: 32px
        height: 20px
        margin-right: 4px
      .shopify
        display: flex
        img
          height: 40px
          width: 40px
          margin-right: 12px
        .shop-name
          align-self: center
    .link
      color: #505763
      font-weight: 500
      font-size: 14px
      text-align: end
      cursor: pointer
</style>
